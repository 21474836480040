// export const BASE_URL = `http://127.0.0.1:8000`;
export const BASE_URL = `https://api.optipack3d.com`;
export const FAILURE = "FAILURE";
export const SUCCESS = "SUCCESS";
export const REGISTERED = "REGISTERED";
export const LOGGEDIN = "LOGGEDIN";
export const LOADING = "LOADING";
export const FULFILLED = "FULFILLED";
export const ERROR = "ERROR";
export const IDLE = "IDLE";
